<template>
    <div class="form-control">
        <div class="progress-box">
            <div class="progress-value" :style="{'width':passLength + '%'}"></div>
        </div>
        <div class="control-title">
            权益人信息
        </div>
        <van-form class="form-box" ref="formRef">
            <van-field placeholder="会员姓名"
                       v-model="formData.name"
                       name="name"
                       @focus="hiddenBtn = true"
                       @blur="hiddenBtn = false"
                       :rules="[{ required: true, message: '请输入会员姓名' }]"
                       maxlength="10"/>
            <van-field placeholder="证件类型"
                       is-link
                       readonly
                       name="typeName"
                       @click="showType = true"
                       v-model="formData.typeName"
                       :rules="[{ required: true, message: '请选择证件类型' }]"/>
            <van-field placeholder="证件号码"
                       v-model="formData.license_no"
                       name="card"
                       @focus="hiddenBtn = true"
                       @blur="hiddenBtn = false"
                       :rules="[{ required: true,  message: '请填写正确的身份证' }]"
                       maxlength="18"/>
            <van-field placeholder="手机号"
                       v-model="formData.tel"
                       @focus="hiddenBtn = true"
                       @blur="hiddenBtn = false"
                       :rules="[{ pattern: /^1\d{10}$/, message: '请填写正确的手机号' }]"
                       name="tel"
                       maxlength="11"/>
            <van-field
                is-link
                readonly
                name="area"
                placeholder="所在城市"
                v-model="formData.address"
                :rules="[{ required: true, message: '请选择所在城市' }]"
                @click="showArea = true"/>
            <van-field
                placeholder="请填写代理人姓名"
                v-model="formData.agent_name"
                @focus="hiddenBtn = true"
                @blur="hiddenBtn = false"
            />
            <van-field
                placeholder="请填写代理人工号"
                v-model="formData.agent_no"
                @focus="hiddenBtn = true"
                @blur="hiddenBtn = false"
            />
        </van-form>
        <div class="form-tip">
            *本权益仅适用于出生28天至65周岁(含)

        </div>
        <div class="agree-control">
            <van-checkbox shape="square" v-model="checked" checked-color="#327F5C" icon-size="15px">
                <div class="agree-text">
                    我已阅读并同意
                    <span @click="showExplain.show1 = true">《特药权益卡服务使用说明》</span>
                    <span @click="showExplain.show2 = true">《个人信息收集处理告知书》</span>
                    <span @click="showExplain.show3 = true">《健康告知》</span>
                    <span @click="showExplain.show4 = true">《注册协议》</span>
                </div>

            </van-checkbox>
        </div>

        <div class="submit-control" v-show="!hiddenBtn">
            <div :class="{'submit-btn':true,'active-submit':passLength == 100}" @click="submit">
                完成激活并绑定权益人
            </div>
        </div>
        <van-popup v-model="showArea" position="bottom">
            <van-area
                :area-list="areaList"
                @confirm="areaConfirm"
                @cancel="showArea = false"
            />
        </van-popup>

        <van-popup v-model="showType" position="bottom">
            <van-picker
                :columns="typeList"
                @confirm="typeConfirm"
                @cancel="showType = false"
            />
        </van-popup>

        <!--特药-->
        <van-popup v-model="showExplain.show1" position="bottom" class="info-form-popup">
            <div class="info-form-content">
                <div class="info-form-title">
                    {{ JsonData[0].title }}
                </div>
                <div class="info-form-text" v-html="JsonData[0].con"/>
            </div>
        </van-popup>

        <!--个人信息-->
        <van-popup v-model:show="showExplain.show2" position="bottom" class="info-form-popup">
            <div class="info-form-content">
                <div class="info-form-title">
                    {{ JsonData[1].title }}
                </div>
                <div class="info-form-text" v-html="JsonData[1].con"/>
            </div>
        </van-popup>
        <!--健康告知-->
        <van-popup v-model:show="showExplain.show3" position="bottom" class="info-form-popup">
            <div class="info-form-content">
                <div class="info-form-title">
                    {{ JsonData[2].title }}
                </div>
                <div class="info-form-text" v-html="JsonData[2].con"/>
            </div>
        </van-popup>
        <van-popup v-model:show="showExplain.show4" position="bottom" class="info-form-popup">
            <div class="info-form-content">
                <div class="info-form-title">
                    {{ JsonData[3].title }}
                </div>
                <div class="info-form-text" v-html="JsonData[3].con"/>
            </div>
        </van-popup>
    </div>

</template>

<script>
import {Toast} from "vant";
import {areaList} from '@vant/area-data';
import JsonData from "./c.json"
import wxShare from "@/wechat/weChatTile";
import qs from "qs";

export default {
    data() {
        return {
            throt: true,
            showArea: false,
            areaList: [],
            hiddenBtn: false,//true隐藏提交按钮
            showType: false,
            typeList: [  //证件
                {
                    text: '身份证',
                    value: 1
                }
            ],
            checked: true,
            formData: {
                name: '',
                typeName: '身份证',
                license_type: 1,
                license_no: '',
                tel: '',
                address: '',
                province: '',
                city: '',
                country: '',
                agent_name: '',
                agent_no: ''
            },
            passLength: 20,
            initHeight: window.innerHeight,
            showExplain: {
                show1: false,
                show2: false,
                show3: false,
                show4: false
            },
            JsonData: [],
        }
    },
    created() {
        if (!this.$route.query.card_no) {
            this.$router.push("/new/ydj/active")
        }
        this.areaList = areaList
        this.JsonData = JsonData
    },
    mounted() {
        let query = qs.stringify(this.$route.query)
        query = query ? `?${query}` : ''
        wxShare({
            title: '一站式健康管理服务平台',
            desc: '康来家园，中国健康管理领导者',
            link: window.location.origin + `/new/ydj${query}`,
        })
        window.onresize = () => {
            let height = document.documentElement.clientHeight || document.body.clientHeight
            if (this.initHeight !== height) {
                this.hiddenBtn = true
            } else {
                this.hiddenBtn = false
            }
        }
    },
    beforeDestroy() {
        window.onresize = () => {
        }
    },
    methods: {
        //选择类型
        typeConfirm(data) {
            this.formData.typeName = data.text
            this.formData.license_type = data.value
            this.showType = false
        },
        //选择城市
        areaConfirm(data) {
            let text = data.map(item => item.name).join("/")
            this.formData.address = text
            this.formData.province = data[0].name
            this.formData.city = data[1].name
            this.formData.country = data[2].name
            this.showArea = false
        },
        //监听
        listenForm() {
            this.$refs.formRef.validate().then(async (valid) => {
                this.passLength = 100
            }).catch((res) => {
                this.passLength = Number(((5 - res.length) / 5).toFixed(2)) * 100
            })
        },
        //提交
        submit() {
            this.$refs.formRef.validate().then(async (valid) => {
                if (!this.checked) {
                    Toast("请阅读并勾选同意书")
                    return
                }
                if (this.throt) {
                    this.throt = false
                    let res = await axios.post("/tyqyk/activate_power_card", {
                        ...this.formData,
                        card_no: this.$route.query.card_no,
                        ...this.$route.query
                    })
                    if (res.code === 1) {
                        await this.$router.replace("/new/ydj/person-interests")
                    } else {
                        Toast(res.message)
                    }
                    setTimeout(() => {
                        this.throt = true
                    }, 2000)
                }
            })
        }

    },
    watch: {
        formData: {
            handler() {
                this.listenForm()
            },
            deep: true
        }
    }
}
</script>

<style lang="less">
.form-control {
    padding: 13px 28px 85px;

    .progress-box {
        width: 100%;
        background: rgba(98, 195, 146, 0.25);
        height: 4px;
        position: relative;

        .progress-value {
            position: absolute;
            left: 0;
            top: 0;
            background: #3C9E6E;
            height: 100%;
            width: 50%;
        }
    }

    .control-title {
        margin-top: 30px;
        margin-bottom: 35px;
        font-size: 25px;
        font-weight: bold;
        color: #134513;
        line-height: 35px;
    }

    .form-box {
        .van-cell__value {
            font-size: 15px;
        }

        .van-cell {
            padding: 17px 0;

            &:after {
                width: 100%;
                left: 0;
                border-color: #ECECEC;
            }
        }
    }

    .form-tip {
        font-size: 13px;
        font-weight: 500;
        color: #999999;
        margin-top: 30px;
        text-align: center;
    }

    .agree-control {
        margin-top: 22px;

        .van-checkbox {
            align-items: flex-start;

            :deep(.van-checkbox__icon) {
                transform: translateY(3px);
            }
        }

        .agree-text {
            font-size: 12px;
            font-weight: 400;
            color: #232323;
            line-height: 17px;

            span {
                color: #327F5C;
            }
        }
    }

    .submit-control {
        position: fixed;
        bottom: 0px;
        left: 0;
        background: #FFFFFF;
        width: 100%;
        padding: 18px 0;
        box-shadow: 0px -3px 10px 0px rgba(224, 224, 224, 0.27);

        .submit-btn {
            width: 80%;
            background: rgba(50, 127, 92, 0.55);
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            color: #114512;
            line-height: 21px;
            margin: auto;
            transition: all 0.5s;
            border-radius: 23px;
            padding: 12px 0;
        }

        .active-submit {
            background: #327F5C;
            color: white;
        }
    }

}

.info-form-popup {
    height: 75vh;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(179deg, #FFF0E2 0%, #DAFFED 48%, rgba(227, 254, 255, 0) 100%);
    background-size: 100% 180px;
    background-repeat: no-repeat;
    background-color: #F2F9FF;

    .info-form-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 0;
        padding: 10px 10px 0;

        .info-form-title {
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            color: #144713;
            padding: 23px 0;
        }

        .info-form-text {
            flex: 1;
            height: 0;
            overflow: auto;
            background: #FBFDFF;
            padding: 10px;
            text-indent: 2em;
            color: #232323;
            font-size: 16px;
            font-size: 14px;
            line-height: 30px;

            p {
                margin-bottom: 5px;
            }

            p:first-of-type {
                margin-bottom: 20px;
                font-weight: 700;
                line-height: 24px;
            }
        }

    }
}
</style>